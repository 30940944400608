import React from 'react';
import { slide as Menu } from 'react-burger-menu'
// import { Link } from 'react-router-dom';

class MenuComponent extends React.Component {
  showSettings (event: { preventDefault: () => void; }) {
    event.preventDefault();
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <div className="menu-container">
        <Menu right>
          <a id="about" className="menu-item" href="/about">About</a>
          <a id="contact" className="menu-item" href="/contact">Contact</a>
        </Menu>
      </div>
    );
  }
}

export { MenuComponent };
